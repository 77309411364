<template>
	<!-- //页面交互部分 -->
	<div class="homeInfo">
		<div class="controlBox">
			<div style="width: 40%"></div>
			<div style="width: 20%; font-size: 20px; color: #fff">施工进度模拟</div>
			<div style="width: 40%" class="mainContr">
				<div class="el-icon-video-play" @click="palyWorkVideo">开始</div>
				<div class="el-icon-video-pause" @click="playPause">暂停</div>
				<div class="el-icon-film" @click="playQuick">
					{{ speed == 0.5 ? "2X" : "1X" }}
				</div>
				<div @click="intoModelPage" class="backIndex">
					<img src="../assets/images/home.svg" alt="" />
					<div class="backHomeBtn">返回主页</div>
				</div>
			</div>
		</div>
		<div class="mediaContainer">
			<video class="videoBox" id="videoBoxWork" preload="auto" :controls="false"
				src="../assets/video/work.mp4"></video>
		</div>
		<div class="firstLeft">
			<div class="info1">
				<div>
					<p class="titleBox">
						<img style="width: 16px" src="../assets/images/dInfo.svg" alt="" /><span>建筑信息</span>
					</p>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-s-flag"></i></span><span class="discTitle">地理位置:</span><span
							class="dis">海淀区学院路30号</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-receiving"></i></span><span
							class="discTitle">占地面积:</span><span class="dis">约14344.87平方米</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-s-data"></i></span><span class="discTitle">建筑高度:</span><span
							class="dis">约31.2米</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-film"></i></span><span class="discTitle">建筑楼层:</span><span
							class="dis">地上6层，地下2层</span>
					</div>
				</div>
			</div>
			<div class="info2">
				<p class="titleBox">
					<span style="line-height: 17px; margin-left: 0" class="el-icon-notebook-2"></span><span>建筑资料</span>
				</p>
				<div class="content" id="box">
					<div>
						<div class="cell_t">
							<span class="celll">项目名称：</span><span class="cellr">工程实践基地（二期）（2#实验楼）项目</span>
						</div>
						<div class="cell_t">
							<span class="celll">工程地点：</span><span class="cellr">北京市海淀区学院路30号</span>
						</div>
						<div class="cell_t">
							<span class="celll">建设单位：</span><span class="cellr">北京科技大学</span>
						</div>
						<div class="cell_t">
							<span class="celll">设计单位：</span><span class="cellr">中科院建筑设计研究院有限公司</span>
						</div>
						<div class="cell_t">
							<span class="celll">监理单位：</span><span class="cellr">北京百事百达工程管理有限公司</span>
						</div>
						<div class="cell_t">
							<span class="celll">勘察单位：</span><span class="cellr">北京中地大工程勘察设计研究院有限责任公司</span>
						</div>
						<div class="cell_t">
							<span class="celll">总包单位：</span><span class="cellr">中国建筑第八工程局有限公司</span>
						</div>
					</div>
					<div class="itemInfoContent" id="ul1" style="line-height: 22px; letter-spacing: 0.2em">
						由中建八局承建的北京科技大学工程实践基地二期（2#实验楼）项目位于北京市海淀区学院路30号，
						地上6层，地下2层，总建筑高度31.2m，总建筑面积14344.87㎡。
						本项目属于校园内施工，现场场地狭小、噪音及扬尘的管控都成为项目施工的重要难点。
						BIM的全周期应用、智能化管理都对施工难点起到了有效的管控及辅助作用。
						工程实践基地二期（2#实验楼）项目建设，使北京科技大学的教学水平有了进一步的提升，
						人才培养有了更广阔的学习空间，也为大学的发展打开了崭新的篇章
					</div>
					<div id="ul2" style="line-height: 22px; letter-spacing: 0.2em"></div>
				</div>
			</div>
		</div>
		<!-- <div class="info3">
			<p class="titleBox">
				<span>工程名</span> <span>施工状态</span>
			</p>
			<div class="content" id="box">
				<div class="itemInfoContent" style="line-height: 22px; letter-spacing: 0.2em">
					<span>科大二期</span><span>施工中</span>

				</div>
				<div id="ul2" style="line-height: 22px; letter-spacing: 0.2em"></div>
			</div>
		</div> -->
		<!-- <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide box_1"></div>
        <div class="swiper-slide box_2"></div>
        <div class="swiper-slide box_3"></div>
        <div class="swiper-slide box_4"></div>
        <div class="swiper-slide box_5"></div>
      </div>
      
    </div> -->
		<!-- <div class="pressBox">
			<div class="upPress">
				<div class="lineBox" v-for="(item,index) in historyList" :key="index">
					<span class="line" :style="{width:item.pointWidth}"></span>
					<span class="point"></span>
				</div>
				<div class="lineBox">
					<span class="line" style="width:10000px;"></span>
				</div>
			</div>
			<div class="workInfo">
				<p class="titles" v-if="showOneS">
					<img class="imges" :src="workUrl" alt="">
					<span class="info">{{workMsg}}</span>
				</p>
				<span class="time" v-if="showOneS">{{workDate}}</span>
			</div>
		</div> -->
		
		<div class="swiper-container">
		    <div class="swiper-wrapper">
		      <div class="swiper-slide" v-for="(item,index) in historyList" :key="index">
						<span class="msg">{{item.info}}</span>
						<img style="width:100%" :src="item.url" alt="">
						<span class="dates">{{item.date}}</span>
					</div>
		    </div>
		    <!-- Add Pagination -->
				<!-- <div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div> -->
		    <div class="swiper-pagination"></div>
		  </div>
	</div>
</template>
<script>
	let ul1;
	let ul2;
	let box;
	let timer;
	export default {
		name: "WorkPress",
		data() {
			return {
				speed: 0.5,
				historyList: [{
						url: 'https://us-1256061997.cos.ap-nanjing.myqcloud.com/us/1.jpg',
						info:'开工仪式',
						date: "2021-11-01" 
					},
					{
						url: 'https://us-1256061997.cos.ap-nanjing.myqcloud.com/us/2.jpg',
						info:'支护桩钢筋笼绑扎',
						date: "2021-11-15"
					},
					{
						url: 'https://us-1256061997.cos.ap-nanjing.myqcloud.com/us/3.jpg',
						info:'土方开挖',
						date: "2021-12-10"
					},
					{
						url: 'https://us-1256061997.cos.ap-nanjing.myqcloud.com/us/4.jpg',
						info:'垫层施工',
						date: "2022-03-01"
					},
					{
						url: 'https://us-1256061997.cos.ap-nanjing.myqcloud.com/us/5.jpg',
						info:'筏板混凝土浇筑',
						date: "2022-04-13"
					}
				],
				pressLineWidthList:[],
				mySwiper: undefined,
				windowWidth:null,
				showOneS:false,
				dayList:[],
				workMsg:'',
				workUrl:'',
				workDate:'',
				dayListSum:null
			};
		},
		created(){
			this.windowWidth = document.body.clientWidth
			// window.addEventListener('resize',this.resizes,false)
			// let num = 0
			// let totalDay = this.countDay(this.historyList[this.historyList.length-1].date)
			// this.historyList.forEach((el,index)=>{
			// 	//获取工程节点到年初的时间天数
			// 	let days = this.countDay(el.date);
			// 	this.dayList.push(days)
			// 	let currentDay = days-num;
			// 	num = days
			// 	let pointWidth = parseInt(this.windowWidth*currentDay/totalDay) + 'px'
			// 	el.pointWidth = pointWidth
			// })
			// this.dayListSum = eval(this.dayList.join('+'))
		},
		// destroyed(){
		// 	window.removeEventListener("resize", this.resizes, false);
		// },
		methods: {
			//页面交互部分
			intoModelPage() {
				this.$router.push({
					name: "Building"
				});
			},
			// resizes(){
			// 	this.windowWidth = document.body.clientWidth
			// },
			countDay(days){
				let  s1 = '2021-7-01';
				let  s2
				s1 = new Date(s1.replace(/-/g, "/"));
				s2 = new Date(days);//当前日期：2017-04-24
				let  day = s2.getTime() - s1.getTime();
				let  time = parseInt(day / (1000 * 60 * 60 * 24));
				return time
			},
			palyWorkVideo() {
				const video = document.getElementById("videoBoxWork");
				video.play();
				video.playbackRate = 0.5;
				// let Container = document.getElementsByClassName('upPress')
				// let that = this
				// //点击播放重置进度
				// Container[0].style.left = '0px';
				// that.workMsg = ""
				// that.workUrl = ""
				// that.workDate = ""
				
				// let constNum = this.dayListSum
				// let allBoxCenter = (this.windowWidth - 280)/2 + 200
				// video.addEventListener("timeupdate", function() {
				// 	// left 移动量	
				// 	try{
				// 		Container[0].style.left = '-' + 1.8*constNum*video.currentTime/video.duration + 'px'
				// 		if($('.lineBox .point').eq(0).offset().left<allBoxCenter&&$('.lineBox .point').eq(1).offset().left>=allBoxCenter){
				// 			that.showOneS = true
				// 			that.workMsg = that.historyList[0].info
				// 			that.workUrl = that.historyList[0].url
				// 			that.workDate = that.historyList[0].date
				// 		}else if($('.lineBox .point').eq(1).offset().left<allBoxCenter&&$('.lineBox .point').eq(2).offset().left>=allBoxCenter){
				// 			that.workMsg = that.historyList[1].info
				// 			that.workUrl = that.historyList[1].url
				// 			that.workDate = that.historyList[1].date
				// 		}else if($('.lineBox .point').eq(2).offset().left<allBoxCenter&&$('.lineBox .point').eq(3).offset().left>=allBoxCenter){
				// 			that.workMsg = that.historyList[2].info
				// 			that.workUrl = that.historyList[2].url
				// 			that.workDate = that.historyList[2].date
				// 		}else if($('.lineBox .point').eq(3).offset().left<allBoxCenter&&$('.lineBox .point').eq(4).offset().left>=allBoxCenter){
				// 			that.workMsg = that.historyList[3].info
				// 			that.workUrl = that.historyList[3].url
				// 			that.workDate = that.historyList[3].date
				// 		}else if($('.lineBox .point').eq(4).offset().left<allBoxCenter){
				// 			that.workMsg = that.historyList[4].info
				// 			that.workUrl = that.historyList[4].url
				// 			that.workDate = that.historyList[4].date
				// 		}
				// 	}catch(err){
				// 		console.log(err)
				// 	}
				// });
			},
			playPause() {
				const videos = document.getElementById("videoBoxWork");
				videos.pause();
			},
			playQuick() {
				const videos = document.getElementById("videoBoxWork");
				videos.play();
				// let Container = document.getElementsByClassName('upPress')
				// let that = this
				// if(videos.currentTime == 0){
				// 	//点击播放重置进度
				// 	Container[0].style.left = '0px';
				// 	that.workMsg = ""
				// 	that.workUrl = ""
				// 	that.workDate = ""
				// }
				if (this.speed == 0.5) {
					videos.playbackRate = 1;
					this.speed = 1;
				} else {
					videos.playbackRate = 0.5;
					this.speed = 0.5;
				}
			},
			hideWorkVideo() {
				this.corFlagPress = false;
			},
		},
		destroyed() {
			// window.removeEventListener('timeupdate')
			// let Container = document.getElementsByClassName('upPress');
			let video = document.getElementById("videoBoxWork");
			video.currentTime = 0
			// let that = this
			// //点击播放重置进度
			// Container[0].style.left = '0px';
			// that.workMsg = ""
			// that.workUrl = ""
			// that.workDate = ""
		},
		mounted() {
			// window.onload = roll(50);

			// function roll(t) {
			// 	ul1 = document.getElementById("ul1");
			// 	ul2 = document.getElementById("ul2");
			// 	box = document.getElementById("box");
			// 	ul2.innerHTML = ul1.innerHTML;
			// 	box.scrollTop = 0;
			// 	timer = setInterval(rollStart, t);
			// 	box.onmouseover = function() {
			// 		clearInterval(timer);
			// 	};
			// 	box.onmouseout = function() {
			// 		timer = setInterval(rollStart, t);
			// 	};
			// }

			// function rollStart() {
			// 	if (box.scrollTop >= ul1.scrollHeight) {
			// 		box.scrollTop = 0;
			// 	} else {
			// 		box.scrollTop++;
			// 	}
			// }
			var swiper = new Swiper('.swiper-container', {
				// navigation: {
				// 	nextEl: ".swiper-button-next",
				// 	prevEl: ".swiper-button-prev",
				// },
				direction: "vertical",
				slidesPerView: 3,
				spaceBetween: 30,
				centeredSlides: true,
				loop: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			});
		},
	};
</script>
<style lang="less" scoped>
	//页面交互
	.homeInfo {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		overflow: hidden;
		z-index: 20000;
	}

	.title {
		width: 100%;
		text-align: center;
		right: 0;
		top: 0;
		position: fixed;
		background: transparent;
		background-image: url("../assets/images/infoBg.png");
		width: 100%;
		height: 76px;
		line-height: 76px;
		font-size: 28px;
		color: #fff;
	}

	.mediaContainer {
		width: 100%;
		height: 100vh;
		position: relative;
		top: 0;
		left: 0;
		z-index: 19888;
		display: block;
	}

	.backIndex {
		height: 36px;
		width: 120px;
		border-radius: 4px;
		padding-top: 4px;
		box-sizing: border-box;
		padding-left: 10px;
		cursor: pointer;
		color: #fff;

		img {
			width: 24px;
			vertical-align: middle;
		}
	}

	.backHomeBtn {
		height: 24px;
		line-height: 24px;
		display: inline-block;
		vertical-align: middle;
	}

	.mediaContainer {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 19888;
		display: block;
	}

	.videoBox {
		width: 100%;
		height: 100%;
		background: #000;
		object-fit: fill;
	}

	/*video默认全屏按钮*/
	video::-webkit-media-controls-fullscreen-button {
		display: none !important;
	}

	:-webkit-full-screen video {
		width: 100%;
		height: 100%;
	}

	:-moz-full-screen video {
		width: 100%;
		height: 100%;
	}

	.info3 {
		width: 222px;
		height: 140px;
		position: absolute;
		z-index: 20000;
		bottom: 70px;
		left: 40px;
		background-image: url("../assets/images/infoBg.png");
		padding: 10px 15px;
		box-sizing: border-box;
		font-size: 13px;

		.titleBox {
			width: 192px;
			height: 30px;
			background: rgb(0, 118, 163);
			border-radius: 2px;

			span {
				display: inline-block;
				vertical-align: middle;
				height: 30px;
				line-height: 30px;
				color: #fff;
				margin-left: 30px;
			}
		}

		.itemInfoContent {
			margin-top: 36px;

			span {
				display: inline-block;
				vertical-align: middle;
				height: 30px;
				line-height: 30px;
				color: #fff;
				margin-left: 20px;
			}
		}
	}

	.firstLeft {
		width: 222px;
		height: 500px;
		position: absolute;
		z-index: 20000;
		top: 220px;
		right: 40px;

		.info1,
		.info2 {
			position: relative;
		}

		.info1 {
			border-radius: 6px;
			height: 154px;
			width: 240px;
			background-image: url("../assets/images/infoBg.png");
		}

		.info2 {
			border-radius: 6px;
			margin-top: 18px;
			// height: 222px;
			width: 240px;
			background-image: url("../assets/images/infoBg.png");

			.content {
				width: 240px;
				// height: 190px;
				overflow: auto;
				padding: 10px 16px;
				box-sizing: border-box;
				text-align: justify;
				color: #fff;
				font-size: 12px;
				line-height: 18px;
			}
		}

		.titleBox {
			padding-top: 2px;
			padding-bottom: 4px;
			padding-left: 10px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			font-size: 13px;

			img {
				vertical-align: middle;
			}

			span {
				margin-left: 8px;
				display: inline-block;
				vertical-align: middle;
				height: 16px;
				line-height: 16px;
				color: rgba(255, 255, 255, 0.7);
			}
		}

		.infoListBox {
			margin-top: 6px;

			span {
				display: inline-block;
				vertical-align: middle;
				font-size: 13px;
			}

			.cle {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: #0076a3;
				margin-left: 10px;

				i {
					color: rgba(255, 255, 255, 0.7);
					margin-left: 4px;
				}
			}

			.discTitle {
				margin-left: 6px;
				color: rgba(255, 255, 255, 0.8);
			}

			.dis {
				margin-left: 6px;
				color: #fff;
			}
		}
	}

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 1px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		background: transparent;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		background: transparent;
	}

	.controlBox {
		position: fixed;
		right: 0;
		top: 0;
		background-image: url("../assets/images/infoBg.png");
		width: 100%;
		height: 76px;
		z-index: 20000;
	}

	.controlBox>div {
		display: inline-block;
		vertical-align: middle;
		margin-top: 20px;
		color: #fff;
	}

	.mainContr {
		text-align: right;
		padding-right: 12px;
		box-sizing: border-box;
	}

	.mainContr>div {
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		margin-left: 12px;
	}
	.pressBox {
		width: calc(100% - 280px);
		position: fixed;
		height: 20px;
		bottom: 134px;
		left: 268px;
		z-index: 300000;
		overflow: hidden;
	}

	.upPress {
		height: 20px;
		width: 1000%;
		border-radius: 2px;
		background: transparent;
		position: absolute;
		left:0px;
		z-index: 300002;

		.lineBox {
			display: inline-block;
			vertical-align: middle;
			span {
				display: inline-block;
				vertical-align: middle;
			}

			.line {
				height: 3px;
				margin-top: 2px;
				background: #0076a3;
			}

			.point {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background-color: #0076a3;
			}

		}
	}

	.workInfo {
		.titles {
			position: fixed;
			z-index: 300004;
			bottom: 170px;
			width: 262px;
			left: calc(50% - 97px);

			.imges {
				width: 260px;
			}

			.info {
				width: 260px;
				text-align: center;
				display: inline-block;
				margin-top: 12px;
				color: #fff;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.time {
			position: fixed;
			z-index: 300004;
			bottom: 110px;
			width: 262px;
			left: calc(50% - 97px);
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			width: 260px;
			text-align: center;
			display: inline-block;
		}
	}
	.cell_t{
		margin-top:6px;
		span{
			display: inline-block;
			vertical-align: top;
			text-align: left;
		}
		.celll{
			width:62px;
			
		}
		.cellr{
			width:144px;
		}
	}
	
	.swiper-container {
		width: 300px;
		height: 600px;
		position: absolute;
		z-index: 40000;
		top: calc(50% - 300px);
		left:30px;
	}
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		transform: scale(0.8);
		.msg{
			color: #fff;
			position: absolute;
			top: 0;
		}
		.dates{
			color: #fff;
			position: absolute;
			bottom: 0;
		}
	}
	.swiper-slide-active,.swiper-slide-duplicate-active{
			transform: scale(1);
	}
</style>
